import React, { useState, useEffect } from 'react';
import './CSCV1.css';  // Import the CSS

function CSCV1() {
  // State to store the questions
  const [questions, setQuestions] = useState([]);
  // State to store the selected choices
  const [selectedAnswers, setSelectedAnswers] = useState({});
  // State to store the available topics
  const [topics, setTopics] = useState([]);
  // State to store the current selected topic
  const [currentTopic, setCurrentTopic] = useState('');

  // Load the JSON data
  useEffect(() => {
    fetch('./updated_adjusted_parsed_tests_final.json')
      .then((response) => response.json())
      .then((data) => {
        const topicKeys = Object.keys(data);
        setTopics(topicKeys);
        if (!currentTopic && topicKeys.length > 0) {
          setCurrentTopic(topicKeys[0]);
        }
        setQuestions(data[currentTopic] || []);
      })
      .catch((error) => console.error("Error fetching data: ", error));
  }, [currentTopic]);

  // Handle choice click
  const handleChoiceClick = (question, choice, correctChoice) => {
    setSelectedAnswers({
      ...selectedAnswers,
      [question]: {
        selected: choice[0].toUpperCase(),
        isCorrect: choice[0].toUpperCase() === correctChoice.toUpperCase()
      }
    });
  };

  // Handle topic change
  const handleTopicChange = (event) => {
    setCurrentTopic(event.target.value);
    setSelectedAnswers({});
  };

  return (
    <div className="CSCV1">
      <h1>CSCV1 App</h1>

      {/* Dropdown to select topic */}
      <select onChange={handleTopicChange} value={currentTopic}>
        {topics.map((topic, index) => (
          <option key={index} value={topic}>
            {topic}
          </option>
        ))}
      </select>

      {/* Display questions */}
      {questions.map((q, index) => (
        <div key={index}>
            <div><strong>{q.question}</strong></div>
            <div className="choice-container">
            {q.choices.map((choice, cIndex) => (
                <button
                className="choice-button"
                key={cIndex}
                onClick={() => handleChoiceClick(q.question, choice, q.correct)}
                style={{
                    backgroundColor: selectedAnswers[q.question]
                    ? (selectedAnswers[q.question].selected === choice[0].toUpperCase()
                        ? (selectedAnswers[q.question].isCorrect ? 'green' : 'red')
                        : 'white')
                    : 'white',
                    color: selectedAnswers[q.question]?.selected === choice[0].toUpperCase() ? 'white' : 'black'
                }}
                >
                {choice}
                </button>
            ))}
            </div>
        </div>
        ))}
    </div>
  );
}

export default CSCV1;
