import { TypeAnimation } from 'react-type-animation';
import './App.css';
import CSCV1 from './cscv1test';


const Typing = () => {
  return (
    <TypeAnimation
      sequence={[
        'ROCKYKO.COM',
        1000, 
        'ROCKYKO.COMEDIAN',
        1000,
        'ROCKYKO.COMPOSER',
        1000,
        'ROCKYKO.COMPILER',
        1000
      ]}
      wrapper="span"
      speed={50}
      style={{ fontSize: '2em', display: 'inline-block' }}
      repeat={Infinity}
    />
  );
};

function JoinButton() {
  return (
    <button>
      Join
    </button>
  );
}
export default function App() {
  return (
    <div className="app-container">
    <div className="red-dot"></div>
    <div className="live-text"> LIVE </div>
    <br />
    <br />
      <Typing />
    <br />  
      <JoinButton />
      <h1>WIP</h1>
      <CSCV1 />
    </div>
  );
}


